const HOSTNAME = process.env.REACT_APP_HOSTNAME;
const API_PREFIX = 'api';
const API_VERSION = 'v1';
export const SERVER_URL = `${HOSTNAME}/${API_PREFIX}/${API_VERSION}/customer`;
export const START_YEAR = 2022;
export const TURMITECH_WEBSITE_URL = 'https://www.turmitech.com';

export const DELIVERY_METHOD = {
  HOME_DELIVERY: 'Home Delivery',
  PICK_UP: 'Pick up',
};

export const UNITS = {
  BOWL: 'BOWL',
  GRAMS: 'GRAMS',
  OUNCES: 'OUNCES',
  POUNDS: 'POUNDS',
  PIECES: 'PIECES',
  SLICES: 'SLICES',
  CUPS: 'CUPS',
  TABLESPOONS: 'TABLE-SPOONS',
  TEASPOONS: 'TEA-SPOONS',
  PORTION: 'PORTION',
};

export const TIFFIN = {
  STATUS: {
    ACTIVE: 'Active',
    EXPIRING_SOON: 'Expiring Soon',
    EXPIRED: 'Expired',
    CANCEL: 'Cancel',
    HOLD: 'Hold',
    NOT_STARTED: 'Not Started',
  },
  PLANS: {
    SINGLE: 'single',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly',
    BIWEEKLY: 'biweekly',
  },
  DELIVERY_ROUTES_STATUS: {
    NOT_READY: 'Not Ready',
    READY: 'Ready',
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled',
  },
  DELIVERY_ROUTES_STOP_STATUS: {
    PENDING: 'Pending',
    ON_THE_WAY: 'On The Way',
    DELIVERED: 'Delivered',
    FAILED: 'Failed',
    CANCELLED: 'Cancelled',
  },
  PAYMENTS: {
    PAID: 'Paid',
    PENDING: 'Pending',
  }
};

export const DAYS = [
  {
    name: 'Sunday',
    shortName: 'Sun',
    label: 'S',
    value: 0,
  },
  {
    name: 'Monday',
    shortName: 'Mon',
    label: 'M',
    value: 1,
  },
  {
    name: 'Tuesday',
    shortName: 'Tue',
    label: 'T',
    value: 2,
  },
  {
    name: 'Wednesday',
    shortName: 'Wed',
    label: 'W',
    value: 3,
  },
  {
    name: 'Thursday',
    shortName: 'Thu',
    label: 'T',
    value: 4,
  },
  {
    name: 'Friday',
    shortName: 'Fri',
    label: 'F',
    value: 5,
  },
  {
    name: 'Saturday',
    shortName: 'Sat',
    label: 'S',
    value: 6,
  },
];