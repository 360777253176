import moment from 'moment';

export const capitalizeFirstLetter = (string) => {
  if (!string) return;
  return string
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)?.toLowerCase())
    .join(' ');
};

export const buildFormData = (formData, data, parentKey) => {
  if (data === null || data === undefined) {
    return;
  }

  if (Array.isArray(data) && data.length === 0) {
    formData.append(parentKey, null);
    return;
  }
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data === null && typeof data === 'string' ? '' : data;
    formData.append(parentKey, value);
  }
};


export const getFormattedDateFromUnixTimestamp = (timestamp) => {
  return moment(timestamp).format('DD MMM YYYY');
};

export const getFormattedTimeFromUnixTimestamp = (timestamp) => {
  return moment(timestamp).format('hh:mm A');
};

export const getFormattedDateTimeFromUnixTimestamp = (timestamp) => {
  const date = getFormattedDateFromUnixTimestamp(timestamp);
  const time = getFormattedTimeFromUnixTimestamp(timestamp);
  return `${date} ${time}`;
};
