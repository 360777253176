import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
    Grid2 as Grid,
    Typography,
    Paper,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
    Divider,
    FormHelperText,
    Select,
    MenuItem,
    InputLabel,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';

import { versionApi, googleMapsApi, mealPlansApi, leadsApi } from '../../../js/slices/api_slices';
import { TuiSpinner, TuiAutoComplete, BaseTextField, Hooks, Copyright, CoreButton, DatePicker, ToggleDays, ContentNotFound } from '../../../core';
import { DELIVERY_METHOD, TIFFIN } from '../../../js/lib/constants';
import { capitalizeFirstLetter } from '../../../js/lib/utils';

const { useGetVersionQuery } = versionApi;
const { useGetMealPlansQuery } = mealPlansApi;
const { useCreateLeadMutation } = leadsApi;
const { useDebounce } = Hooks;
const { useGetPlacesQuery } = googleMapsApi;
const defaultColor = '#008080';

export default function LeadForm() {

    const [mealPlanTypes, setMealPlanTypes] = useState([]);

    // Address states
    const [addressInputValue, setAddressInputValue] = useState('');
    const debouncedAddressInput = useDebounce(addressInputValue, 500);

    // Meal states
    const [mealInputValue, setMealInputValue] = useState('');
    const debounceMealInput = useDebounce(mealInputValue, 500);

    const { data: addressData,
        isLoading: addressLoading } = useGetPlacesQuery({
            input: encodeURIComponent(debouncedAddressInput),
        }, { refetchOnMountOrArgChange: true, skip: addressInputValue ? false : true });

    const {
        data,
        isLoading,
        isError
    } = useGetVersionQuery({}, { refetchOnMountOrArgChange: true });

    const [addNewLead, {
        isSuccess: leadIsSuccess,
        isError: leadIsError,
        isLoading: leadLoading,
    }] = useCreateLeadMutation();

    const {
        data: mealPlansData,
        isLoading: mealPlanApiLoading,
    } = useGetMealPlansQuery({
        input: debounceMealInput,
    }, { refetchOnMountOrArgChange: true, skip: !data?.data });

    const { register, handleSubmit, formState: { errors, isDirty }, control, watch, reset } = useForm({
        defaultValues: {
            planType: TIFFIN.PLANS.SINGLE,
            shipping: DELIVERY_METHOD.HOME_DELIVERY,
            deliveryDays: data?.data?.deliveryDays || [],
            startDate: moment().add(1, 'days').toDate(),
        },
        mode: 'onChange',
    })

    useEffect(() => {
        reset({
            planType: TIFFIN.PLANS.SINGLE,
            shipping: DELIVERY_METHOD.HOME_DELIVERY,
            deliveryDays: data?.data?.deliveryDays || [],
            startDate: moment().add(1, 'days').toDate(),
        });
    }, [data]);

    useEffect(() => {
        const mealPlan = watch('mealPlan');
        const shipping = watch('shipping');
        if (mealPlan) {
            const priceObject = mealPlan?.price?.[shipping === DELIVERY_METHOD.HOME_DELIVERY ? 'delivery' : 'pickUp'] || {};
            const planTypeKeys = [];
            for (const key in priceObject) {
                if (priceObject[key] > 0) {
                    planTypeKeys.push(key);
                }
            }
            setMealPlanTypes(planTypeKeys);
        };
    }, [watch('mealPlan')]);

    const deliveryMethod = watch('shipping') === DELIVERY_METHOD.HOME_DELIVERY ? 'delivery' : 'pickUp';
    const meal = watch('mealPlan');
    const plans = meal?.price?.[deliveryMethod] || {};
    const customColor = data?.data?.leadPortal?.styling?.buttonColor || defaultColor;

    if (isError) {
        return <ContentNotFound />;
    }

    if (isLoading || mealPlanApiLoading || leadLoading) {
        return <TuiSpinner />;
    }

    return (
        <Grid container sx={{
            p: 2,
            backgroundImage: data?.data?.leadPortal?.styling?.backgroundImage ? `url(${data?.data?.leadPortal?.styling?.backgroundImage})` : 'none',
            minHeight: '100vh',
        }} justifyContent='center' alignContent='center' alignItems='center'>
            <Grid size={12} container justifyContent='center'>
                {data?.data?.leadPortal?.styling?.logo && <img
                    style={{
                        maxHeight: '100px',
                        maxWidth: '150px',
                        objectFit: 'fill',
                    }}
                    src={data?.data?.leadPortal?.styling?.logo}
                />}

            </Grid>
            <Grid size={12} justifyContent='center' container>
                <Typography variant="h4">{data?.data?.name}</Typography>
            </Grid>
            {!leadIsSuccess && !leadIsError && <Grid
                sx={{ mt: 2 }}
                container
                size={12}
                justifyContent="center"
                alignItems="center"
                alignContent='center'
            >
                <Paper elevation={3} sx={{ padding: 4, maxWidth: 700 }}>
                    <Grid
                        container
                        size={12}
                        sx={{ mt: 1 }}
                        justifyContent='center'
                        spacing={3}
                        component='form'
                        onSubmit={handleSubmit((userInput) => {
                            const planType = watch('planType');
                            const amount = plans[planType] || 0;
                            userInput.mealPlan = {
                                ...userInput.mealPlan,
                                plan: {
                                    category: 'Scheduled',
                                    type: planType,
                                    count: 1,
                                    price: amount || 0,
                                }
                            };
                            userInput.startDate = moment(userInput.startDate).startOf('day').valueOf();
                            userInput.endDate = moment(userInput.startDate).add(1, planType).endOf('day').valueOf();
                            userInput.address = userInput?.address?.description;
                            addNewLead(userInput);
                        })}>
                        <Grid size={12} container spacing={3}>
                            <Grid container size={12} spacing={2} justifyContent='flex-start'>
                                <Controller
                                    rules={{ required: 'Invalid Shipping Method' }}
                                    control={control}
                                    name="shipping"
                                    render={({ field }) => {
                                        return (
                                            <FormControl fullWidth>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Grid size={6}>
                                                        <FormLabel component="legend">Shipping</FormLabel>
                                                    </Grid>
                                                    <Grid size={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                        <RadioGroup
                                                            {...field}
                                                            onChange={(event, value) => {
                                                                field.onChange(value);
                                                            }}
                                                            value={field?.value}
                                                            row
                                                            aria-labelledby="via-buttons-group-label"
                                                            name="shipping"
                                                        >
                                                            <FormControlLabel
                                                                value={DELIVERY_METHOD.PICK_UP}
                                                                control={<Radio sx={{
                                                                    '&, &.Mui-checked': {
                                                                        color: customColor
                                                                    },
                                                                }} />}
                                                                label="Pick Up"
                                                            />
                                                            <FormControlLabel
                                                                value={DELIVERY_METHOD.HOME_DELIVERY}
                                                                control={<Radio
                                                                    sx={{
                                                                        '&, &.Mui-checked': {
                                                                            color: customColor,
                                                                        },
                                                                    }}
                                                                />}
                                                                label="Home Delivery" />
                                                        </RadioGroup>
                                                    </Grid>
                                                </div>
                                            </FormControl>
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid container size={12} spacing={3}>
                                <Grid size={{ xs: 12, md: 5 }}>
                                    <Controller
                                        control={control}
                                        rules={{ required: 'Meal type must be valid' }}
                                        name="mealPlan"
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <TuiAutoComplete
                                                    fullWidth
                                                    size='small'
                                                    id="mealPlan"
                                                    name="mealPlan"
                                                    autoComplete="off"
                                                    loading={mealPlanApiLoading}
                                                    errors={errors}
                                                    onChange={(event, item) => {
                                                        onChange(item);
                                                    }}
                                                    value={(value?.name || value?.name === '') ? value : null}
                                                    label="Select Meal"
                                                    labelKey='name'
                                                    options={mealPlansData?.data?.items}
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid size={{ xs: 12, md: 3 }}>
                                    <Controller
                                        rules={{ required: 'Invalid Meal Plan Type' }}
                                        control={control}
                                        name="planType"
                                        render={({ field }) => {
                                            return (
                                                <FormControl
                                                    required={true}
                                                    fullWidth={true}
                                                >
                                                    <InputLabel
                                                        id="planType"
                                                        sx={{
                                                            color: errors?.['planType']?.message ? 'secondary.contrastText.100' : 'default',
                                                        }}>
                                                        Plan Type
                                                    </InputLabel>
                                                    <Select
                                                        size='small'
                                                        disabled={!watch('mealPlan')}
                                                        name="planType"
                                                        id="planType"
                                                        error={!!errors?.['planType']?.message}
                                                        label="Plan Type"
                                                        onChange={(event) => field.onChange(event.target.value)}
                                                        value={field?.value}
                                                    >
                                                        {mealPlanTypes.map((item) => <MenuItem key={item} value={item}>{capitalizeFirstLetter(item)}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid size={{ xs: 12, md: 4 }}>
                                    <Controller
                                        control={control}
                                        rules={{ required: 'Invalid Start Date' }}
                                        name="startDate"
                                        render={({ field: { onChange, value } }) => (
                                            <DatePicker
                                                name="startDate"
                                                id="startDate"
                                                size='small'
                                                control={control}
                                                onChange={(event) => {
                                                    onChange(event);
                                                }}
                                                errors={errors}
                                                value={value ? moment(value) : null}
                                                label="Start Date"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                                <BaseTextField
                                    size='small'
                                    id='firstName'
                                    name="firstName"
                                    label="First Name"
                                    errors={errors}
                                    validate={register('firstName', {
                                        required: 'First name is required',
                                        maxLength: {
                                            value: 500,
                                            message: 'Length must be less than 500',
                                        },
                                    },
                                    )}
                                />
                            </Grid>
                            <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                                <BaseTextField
                                    size='small'
                                    id='lastName'
                                    name="lastName"
                                    label="Last Name (Optional)"
                                    required={false}
                                    errors={errors}
                                    validate={register('lastName', {
                                        maxLength: {
                                            value: 500,
                                            message: 'Length must be less than 500',
                                        },
                                    },
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid size={12} container spacing={3}>
                            <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                                <BaseTextField
                                    id='phoneNumber'
                                    size='small'
                                    name="phoneNumber"
                                    label="Phone Number"
                                    errors={errors}
                                    startAdornment='+1'
                                    validate={register('phoneNumber.number', {
                                        required: 'Phone number is required',
                                        maxLength: {
                                            value: 10,
                                            message: 'Length must be 10',
                                        },
                                    },
                                    )}
                                />
                            </Grid>
                            <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                                <BaseTextField
                                    size='small'
                                    id='email'
                                    name="email"
                                    label="Email (Optional)"
                                    type="email"
                                    errors={errors}
                                    required={false}
                                    validate={register('email', {
                                        pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: 'Entered value does not match email format',
                                        },
                                    },
                                    )}
                                />
                            </Grid>
                        </Grid>
                        {watch('shipping') === DELIVERY_METHOD.HOME_DELIVERY && <Grid container size={12} spacing={3}>
                            <Grid size={{ xs: 12, sm: 3, md: 3 }}>
                                <BaseTextField
                                    size='small'
                                    name="unit"
                                    id="unit"
                                    label='Unit (Optional)'
                                    errors={errors}
                                    required={false}
                                    validate={register('unit', {
                                        maxLength: {
                                            value: 500,
                                            message: 'Unit must be less than 500',
                                        },
                                    })}
                                />
                            </Grid>
                            <Grid size={{ xs: 12, sm: 9, md: 9 }}>
                                <Controller
                                    control={control}
                                    name="address"
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            <TuiAutoComplete
                                                id="address"
                                                size='small'
                                                name="address"
                                                autoComplete="off"
                                                required={watch('shipping') === DELIVERY_METHOD.HOME_DELIVERY}
                                                loading={addressLoading}
                                                freeSolo
                                                errors={errors}
                                                onChange={(event, item) => {
                                                    onChange(item);
                                                }}
                                                onInputChange={(event, newInputValue) => {
                                                    setAddressInputValue(newInputValue);
                                                }}
                                                value={(value?.description || value?.description === '') ? value : null}
                                                helperText={'Please do not enter unit number in the address field'}
                                                label="Address"
                                                labelKey='description'
                                                options={addressData?.data?.items}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>}
                        {watch('shipping') === DELIVERY_METHOD.HOME_DELIVERY && <Grid size={12} container>
                            <Controller
                                rules={{ required: 'Select valid delivery days' }}
                                control={control}
                                name="deliveryDays"
                                render={({ field }) => {
                                    return (
                                        <FormControl fullWidth error={!!errors?.deliveryDays?.message}>
                                            <Grid container size={12}>
                                                <Grid size={3}>
                                                    <FormLabel component="legend">Delivery Days</FormLabel>
                                                </Grid>
                                                <Grid size={{ xs: 12, md: 9 }}>
                                                    <ToggleDays field={field} existingDays={data?.data?.deliveryDays} customColor={customColor} boxMaxWidth={60} />
                                                    <FormHelperText>{errors?.deliveryDays?.message}</FormHelperText>
                                                </Grid>
                                            </Grid>
                                        </FormControl>
                                    );
                                }}
                            />
                        </Grid>}
                        <Grid size={12} sx={{ mt: 2 }}>
                            <BaseTextField
                                name="packingInstructions"
                                id="packingInstructions"
                                size='small'
                                multiline
                                rows={2}
                                label='Comments (allergies, dietary restrictions, delivery instructions, etc.)'
                                errors={errors}
                                required={false}
                                validate={register("packingInstructions", {
                                    maxLength: {
                                        value: 500,
                                        message: 'Comments must be less than 500',
                                    },
                                })}
                            />
                        </Grid>
                        <Grid container size={12}>
                            <Grid size={6}>
                                <Typography>Total</Typography>
                            </Grid>
                            <Grid container size={6} justifyContent='flex-end'>
                                <Grid size='auto'>
                                    <Typography variant='h6' fontWeight='bold'>$ {plans[watch('planType')]}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid size={12}>
                            <Divider />
                        </Grid>
                        <Grid container size={12} spacing={1} justifyContent='flex-end' alignContent='end' alignItems='end'>
                            <Grid size={{ md: 6, xs: 12 }}>
                                <CoreButton
                                    variant='contained'
                                    type='submit'
                                    sx={{
                                        backgroundColor: customColor,
                                        color: 'white',
                                    }}
                                    fullWidth
                                    disabled={!isDirty}
                                >
                                    Order Now
                                </CoreButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>}
            {leadIsSuccess && !leadIsError && <Grid
                sx={{ mt: 4 }}
                container
                size={12}
                justifyContent="center"
                alignItems="center"
                alignContent='center'
            >
                <Paper elevation={3} sx={{ padding: 4, maxWidth: 700 }}>
                    <Grid
                        container
                        size={12}
                        sx={{ mt: 1 }}
                        justifyContent='center'
                        spacing={3}
                    >
                        <Grid size={12} justifyContent='center' container>
                            <CheckCircleOutlineIcon color='success' sx={{ fontSize: 200 }} />
                        </Grid>
                        <Grid size={12} justifyContent='center' container>
                            <Typography variant='h3' fontWeight='bold' alignContent='center' >Thank you</Typography>
                        </Grid>
                        <Grid size={12} justifyContent='center' container>
                            <Typography variant='h5'>We will be in touch with you as soon as possible.</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>}
            {!leadIsSuccess && leadIsError && <Grid
                sx={{ mt: 4 }}
                container
                size={12}
                justifyContent="center"
                alignItems="center"
                alignContent='center'
            >
                <Paper elevation={3} sx={{ padding: 4, maxWidth: 700 }}>
                    <Grid
                        container
                        size={12}
                        sx={{ mt: 1 }}
                        justifyContent='center'
                        spacing={3}
                    >
                        <Grid size={12} justifyContent='center' container>
                            <CancelIcon color='error' sx={{ fontSize: 200 }} />
                        </Grid>
                        <Grid size={12} justifyContent='center' container>
                            <Typography variant='h3' fontWeight='bold' alignContent='center' >Sorry</Typography>
                        </Grid>
                        <Grid size={12} justifyContent='center' container>
                            <Typography variant='h5'>Submission failed. Please try again.</Typography>
                        </Grid>
                        <Grid size={12} justifyContent='center' container>
                            <CoreButton
                                variant='contained'
                                fullWidth={false}
                                sx={{
                                    backgroundColor: customColor,
                                    color: 'white',
                                }}
                                onClickHandler={() => {
                                    window.location.reload();
                                }}>
                                Try Again
                            </CoreButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>}
            <Grid size={12} container justifyContent='center' alignContent='flex-end'>
                <Copyright />
            </Grid>
        </Grid >
    );
}
