import React from 'react';
import {
  Grid2 as Grid,
} from '@mui/material';

import { DAYS } from '../js/lib/constants';
import BaseTextField from './base_text_field';

const ToggleDays = ({ field, existingDays, disabled = false, customColor, boxMaxWidth = 80, decimalAllowed = false }) => {

  const handleChange = (day, value) => {
    if (value === '') {
      const updatedDays = { ...field.value, [day]: 0 };
      field.onChange(updatedDays);
      return;
    }
    const floatValue = parseFloat(value);
    const updatedDays = { ...field.value, [day]: decimalAllowed ? floatValue : Math.floor(floatValue) };
    field.onChange(updatedDays);
  };

  return (
    <Grid container spacing={1}>
      {DAYS.map((day, index) => (
        existingDays[day?.value] > 0 && <Grid size={2} key={index} sx={{ maxWidth: boxMaxWidth }}>
          <BaseTextField
            label={day.shortName}
            type="number"
            value={field.value?.[index]}
            onChange={(e) => handleChange(index, e.target.value)}
            disabled={disabled}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: 0,
              step: decimalAllowed ? "any" : 1,
              inputMode: decimalAllowed ? "decimal" : "numeric",
              pattern: decimalAllowed ? "[0-9]*[.,]?[0-9]*" : "[0-9]*",
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ToggleDays;